import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { url } from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/products`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('products/all', async params => {
  const response = await axios.get(endpoint, {params})
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const get = createAsyncThunk('products/get', async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk('products/add', async (item, { dispatch }) => {
  await axios.post(endpoint, item)
  await dispatch(getAll())
  return item
})

export const update = createAsyncThunk('products/update', async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item[0].id}`, item[0])
  await dispatch(getAll())
  return item
})

export const remove = createAsyncThunk('products/remove', async (item) => {
  const response = await axios.delete(`${endpoint}/${item.id}`)
  return response
})

export const productStatus = createAsyncThunk('status/remove', async (status) => {
  return status
})

export const getAllProducts = createAsyncThunk('listProducts', async () => {
  const response = await axios.get(endpoint)
  return response
}) 

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    status: '',
    total: 1,
    params: {},
    selected: null,
    error: '',
    addProduct: {
      statusText: '',
      payload: ''
    },
    updateProduct: {
      statusText: '',
      payload: ''
    },
    deletedProduct: {
      statusText: '',
      payload: ''
    },
    allProducts: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'READY'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'READY'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SUCCESS'
        state.addProduct.statusText = 'OK'
        state.addProduct.payload = action.payload
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload || 'nao deu'
        state.loading = false
        state.addProduct.statusText = 'ERROR'
        state.addProduct.payload = action.payload
      })
      .addCase(update.fulfilled, (state, action) => {
        state.updateProduct.payload = action.payload
        state.updateProduct.statusText = `OK ${Math.random()}`
      })
      .addCase(update.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'
        state.updateProduct.statusText = `ERROR ${Math.random()}`
        state.updateProduct.payload = action.payload
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.deletedProduct.payload = action.payload
        state.deletedProduct.statusText = 'OK'
      })
      .addCase(remove.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'
        state.deletedProduct.payload = action.payload
        state.deletedProduct.statusText = 'ERROR'
      })
      .addCase(productStatus.fulfilled, (state, action) => {
        state.addProduct.statusText = ''
        state.addProduct.payload = action.payload
        state.updateProduct.statusText = ''
        state.updateProduct.payload = action.payload
        state.deletedProduct.payload = action.payload
        state.deletedProduct.statusText = ''
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.allProducts = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
  }
})

export default productsSlice.reducer
