import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { url } from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/warehouses`
axios.defaults.withCredentials = true

export const getWarehouse = createAsyncThunk('all', async params => {
  const response = await axios.get(endpoint, { params })
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const getAllWarehouse = createAsyncThunk('listWarehouse', async () => {
  const response = await axios.get(`${endpoint}`)
  return response
})

export const get = createAsyncThunk('get', async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk('add', async (item, { dispatch }) => {
  await axios.post(endpoint, item)
  await dispatch(getAllWarehouse())
  return item
})

export const update = createAsyncThunk('update', async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(getAllWarehouse())
  await dispatch(getWarehouse())
  return item
})

export const remove = createAsyncThunk('remove', async (item, { dispatch }) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAllWarehouse())
  return item
})

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    allWarehouse: [],
    remove: {
      statusText: '',
      payload: ''
    },
    add: {
      statusText: '',
      payload: ''
    },
    update: {
      statusText: '',
      payload: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getWarehouse.fulfilled, (state, action) => {
        action?.meta?.arg?.perPage !== undefined ? state.data = action.payload.data : state.data = null
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'READY'
      })
      .addCase(getWarehouse.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'READY'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SUCCESS'
        state.add.statusText = `SUCCESS ${Math.random()}`
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.error || 'nao deu'
        state.loading = false
        state.add.statusText = `ERROR ${Math.random()}`
      })
      .addCase(update.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SUCCESS'
        state.update.statusText = `SUCCESS ${Math.random()}`
      })
      .addCase(update.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'
        state.update.statusText = `ERROR ${Math.random()}`
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.remove.payload = action.payload
        state.remove.statusText = `Success ${Math.random()}`
      })
      .addCase(remove.rejected, (state, action) => {
        state.remove.payload = action.payload
        state.remove.statusText = "error"
      })
      .addCase(getAllWarehouse.fulfilled, (state, action) => {
        state.allWarehouse = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllWarehouse.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
  }
})

export default warehouseSlice.reducer
