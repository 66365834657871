import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../../services/url'
import axios from 'axios'

export const getMonthlyOutputPatient = createAsyncThunk('getmonthly_output', async (params) => {
  const { idPatient } = params
  const response = await axios.get(`${url}/patient/monthly_output/${idPatient}`)
  return response
})

export const getHistoric = createAsyncThunk('historic', async () => {
  const response = await axios.get(`${url}/monthly_output?pages=50`)
  return response
})

export const getHistorics = createAsyncThunk('historic', async () => {
  const response = await axios.get(`${url}/monthly_output?pages=50`)
  return response
})

export const confirmOutputItem = createAsyncThunk('monthly_output_historic', async (item) => {
  await axios.post(`${url}/monthly_output_item_historic/collection`, item)
  return item
})

export const addItem = createAsyncThunk('add', async (item, { dispatch }) => {
  await axios.post(`${url}/monthly_output_item/collection`, item)
  await dispatch(getHistoric())
  return item
})

export const deleteMonthlyOutputPatient = createAsyncThunk('deletemonthly_output', async (params) => {
  const response = await axios.delete(`${url}/monthly_output_item/${params}`)
  return response
})

export const updateMonthlyOutputPatient = createAsyncThunk('updatemonthly_output', async (params) => {
  const response = await axios.put(`${url}/monthly_output_item/${params?.id}`, params)
  return response
})

export const addHistoric = createAsyncThunk('addHistoric', async (params) => {
  const response = await axios.post(`${url}/monthly_output_historic`, params)
  return response
})

export const monthly_output = createSlice({
  name: 'monthly_output',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    sendFilter: {
      statusText: '',
      payload: ''
    }, 
    historic: '',
    output_patient: '',
    historicItems: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMonthlyOutputPatient.fulfilled, (state, action) => {
        state.data = action.payload?.data
        state.status = 'READY'
      })
      .addCase(getMonthlyOutputPatient.pending, (state) => {
        state.data = true
        state.status = 'PENDING'
      })
      .addCase(confirmOutputItem.fulfilled, (state, action) => {
        state.output_patient = action.payload.data
        state.status = 'READY'
      })
      .addCase(confirmOutputItem.pending, (state) => {
        state.output_patient = true
        state.status = 'PENDING'
      })
      .addCase(getHistoric.fulfilled, (state, action) => {
        state.historic = action.payload.data
        state.status = 'READY'
      })
      .addCase(getHistoric.pending, (state) => {
        state.historic = true
        state.status = 'PENDING'
      })
      .addCase(addItem.fulfilled, (state, action) => {
        state.historic = action.payload.data
        state.status = 'READY'
      })
      .addCase(addItem.pending, (state) => {
        state.historic = true
        state.status = 'PENDING'
      })
      .addCase(deleteMonthlyOutputPatient.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'READY'
      })
      .addCase(deleteMonthlyOutputPatient.pending, (state) => {
        state.data = true
        state.status = 'PENDING'
      })
      .addCase(updateMonthlyOutputPatient.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'READY'
      })
      .addCase(updateMonthlyOutputPatient.pending, (state) => {
        state.data = true
        state.status = 'PENDING'
      })
      .addCase(addHistoric.fulfilled, (state, action) => {
        state.historicItems = action.payload.data
        state.status = 'READY'
      })
      .addCase(addHistoric.pending, (state) => {
        state.historicItems = true
        state.status = 'PENDING'
      })
  }
})

export default monthly_output.reducer
