// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
 import {url} from '../../../../services/url'
// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

export const getData = createAsyncThunk('states', async params => {
  axios.defaults.withCredentials = true
  const response = await axios.get(`${url}/states`, {params})
  return { data: response.data.states, params }
})

export const citiesSlice = createSlice({
  name: 'states',
  initialState: {
    data: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export default citiesSlice.reducer
