import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '@src/services/url'
import axios from 'axios'

const endpoint = `${url}/user_specialties`

export const getAll = createAsyncThunk('all/user_specialties', async params => {
  const { data: { data, meta: { total, per_page } } } = await axios.get(`${url}/public/specialtiesUser`, { params })
  return {
    data,
    total,
    perPage: per_page,
    params
  }
})

export const create = createAsyncThunk('create/user_specialties', async (body, { dispatch }) => {
  const data = await axios.post(endpoint, body)
  await dispatch(getAll())
  return data
})

export const update = createAsyncThunk('update/user_specialties', async (body) => {
  await axios.put(`${endpoint}/${body[0].id}`, body[0])
  return body
})

export const clearStatus = createAsyncThunk('status/user_specialties', async (status) => {
  return status
})

export const remove = createAsyncThunk('remove/user_specialties', async (id) => {
  const data = await axios.delete(`${endpoint}/${id}`)

  return data
})

export const getAllSpecialties = createAsyncThunk('listSuppliers', async () => {
  const response = await axios.get(`${url}/user_specialties`)
  return response
})

export const specialties = createSlice({
  name: 'user_specialtiess',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    notification: {
      status: '',
      text: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = 'FULFIELLED'
      })
      .addCase(getAll.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(create.fulfilled, (state, action) => {
        state.notification.text = action.payload.data.message
        state.notification.status = action.payload.status
        state.status = 'FULFIELLED'
      })
      .addCase(create.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(create.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.notification.status = action.payload.status
        state.notification.text = action.payload?.data?.message
        state.status = "Atualizado"
      })
      .addCase(update.rejected, (state, action) => {
        state.notification.text = action.error
        state.notification.status = "ERROR"
      })
      .addCase(clearStatus.fulfilled, (state) => {
        state.notification.text = ''
        state.notification.status = ''
        state.status = 'READY'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.notification.status = action.payload.status
        state.notification.text = action.payload.data.message
        state.status = 'REMOVED'
      })
      .addCase(remove.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(remove.rejected, (state) => {
        state.status = 'ERROR'
      })
      .addCase(getAllSpecialties.fulfilled, (state, action) => {
        state.alluser_specialties = action.payload.data.data
        state.status = 'READY'
      })
      .addCase(getAllSpecialties.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
  }
})

export default specialties.reducer
