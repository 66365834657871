import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../services/url'
import axios from 'axios'

const endpoint = `${url}/devices`

export const getAll = createAsyncThunk('all/device', async params => {
  return await axios.get(endpoint, { params })
})

export const create = createAsyncThunk('create/device', async (body, { dispatch }) => {
  const data = await axios.post(endpoint, body)
  await dispatch(getAll())
  return data
})

export const update = createAsyncThunk('update/device', async (body) => {
 return await axios.put(`${endpoint}/${body.id}`, body)
})

export const get = createAsyncThunk('update/device', async (id) => {
 return await axios.get(`${endpoint}/${id}`)
})

export const remove = createAsyncThunk('remove/device', async (id) => {
  return await axios.delete(`${endpoint}/${id}`)
})


export const storeDevice = createSlice({
  name: 'devices',
  initialState: {
    data: [],
    item: {},
    status: 'READY',
    total: 1,
    params: {},
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data.data
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(create.fulfilled, (state) => {
        state.status = 'SAVE_SUCCESS'
      })
      .addCase(create.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(create.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = "SAVE_SUCCESS"
        state.item = action.payload.data.data
      })
      .addCase(update.rejected, (state, action) => {
        state.error = action.error
        state.status = "ERROR"
      })
      .addCase(remove.fulfilled, (state) => {
        state.status = 'DELETE_SUCCESS'
      })
      .addCase(remove.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(remove.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
  }
})

export default storeDevice.reducer
