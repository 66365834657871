import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/prescription`

export const getPrescription = createAsyncThunk('getPrescription/prescription', async () => {
  const response = await axios.get(endpoint)
  return response
})

export const prescription = createSlice({
  name: 'prescription',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    sendFilter: {
      statusText: '',
      payload: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPrescription.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'READY'
      })
      .addCase(getPrescription.pending, (state) => {
        state.data = true
        state.status = 'PENDING'
      })
  }
})

export default prescription.reducer
