import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {url} from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/kit_product`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('kit_product/all', async params => {
  const response = await axios.get(endpoint, {params})
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const getAllkit_product = createAsyncThunk('listkit_product', async () => {
  const response = await axios.get(`${url}/kit_product`)
  return response
})

export const kit_productSlice = createSlice({
  name: 'kit_product',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    allkit_product: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'READY'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(getAllkit_product.fulfilled, (state, action) => {
        state.allkit_product = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllkit_product.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
  }
})

export default kit_productSlice.reducer
