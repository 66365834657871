import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {url} from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/stocks`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('stocks/all', async params => {
  const response = await axios.get(endpoint, {params})
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const getAllstocks = createAsyncThunk('liststocks', async () => {
  const response = await axios.get(`${url}/stocks`)
  return response
})

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    allstocks: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'READY'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(getAllstocks.fulfilled, (state, action) => {
        state.allstocks = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllstocks.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
  }
})

export default stocksSlice.reducer
