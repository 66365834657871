import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {url} from '../../../../services/url'
import axios from 'axios'

// const endpointKitProduct = `${url}/kit_product/collection`
axios.defaults.withCredentials = true

export const getAllKit = createAsyncThunk('all', async params => {
  const response = await axios.get(`${url}/kit/`, {params})
  return { allData: response.data.data, data: response.data.data, total: response.data?.meta?.total, params }
})

export const getAllKits = createAsyncThunk('all-kits', async () => {
  const response = await axios.get(`${url}/kit/`)
  return response
})


export const addKitProduct = createAsyncThunk('add-product', async (item, { dispatch }) => {
  await axios.post(`${url}/kit_product/collection`, item)
  await dispatch(getAllKit())
  await dispatch(getAllKits())
  return item
})

export const addKitService = createAsyncThunk('add-service', async (item, { dispatch }) => {
  await axios.post(`${url}/kit_service/collection`, item)
  await dispatch(getAllKit())
  return item
})


export const updateKit = createAsyncThunk('update', async (item, {dispatch}) => {
  await axios.put(`${url}/kit/${item?.id}`, item)
  await dispatch(getAllKit())
  return item
})

export const removeKit = createAsyncThunk('remove', async (item, {dispatch}) => {
  await axios.delete(`${url}/kit/${item?.id}`)
  await dispatch(getAllKit())
  return item
})

export const kitProductSlice = createSlice({
  name: 'kits',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    allKit: [],
    kit: [],
    updateKit: {
      statusText: "",
      payload: ""
    },
    removeKit: {
      statusText: "",
      payload: ""
    },
    addKitProducts: {
      statusText: "",
      payload: ""
    },
    addKitServices: {
      statusText: "",
      payload: ""
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAllKit.fulfilled, (state, action) => {
          (action.payload.total !== undefined) ? state.data = action.payload.data : state.data = null
          state.allKit = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
          state.status = 'READY'
        })
        .addCase(getAllKit.pending, (state) => {
            state.loading = true
            state.status = 'PENDING'
        })
        .addCase(addKitProduct.fulfilled, (state, action) => {
            state.selected = action.payload
            state.status = 'SUCCESS'
            state.addKitProducts.statusText = `SUCCESS ${Math.random()}`
        })
        .addCase(addKitProduct.rejected, (state, action) => {
            state.status = 'ERROR'
            state.error = action.payload || 'nao deu'
            state.loading = false
            state.addKitProducts.statusText = `ERROR ${Math.random()}`
        })
        .addCase(addKitService.fulfilled, (state, action) => {
          state.selected = action.payload
          state.status = 'SUCCESS'
          state.addKitServices.statusText = `SUCCESS ${Math.random()}`
      })
        .addCase(addKitService.rejected, (state, action) => {
          state.status = 'ERROR'
          state.error = action.payload || 'nao deu'
          state.loading = false
          state.addKitServices.statusText = `ERROR ${Math.random()}`
        })
        .addCase(updateKit.fulfilled, (state, action) => {
          state.selected = action.payload
          state.status = 'SUCCESS'
          state.updateKit.statusText = `SUCCESS ${Math.random()}`
        })
        .addCase(updateKit.rejected, (state, action) => {
          state.selected = action.error
            state.status = 'ERROR'
            state.updateKit.statusText = `ERROR ${Math.random()}`
        })
        .addCase(removeKit.rejected, (state, action) => {
          state.selected = action.error
          state.status = 'ERROR'
          state.removeKit.statusText = `ERROR ${Math.random()}`
        })
        .addCase(removeKit.fulfilled, (state, action) => {
          state.allCategories = action
          state.status = 'READY'
          state.removeKit.statusText = `SUCCESS ${Math.random()}`
        })
        .addCase(getAllKits.fulfilled, (state, action) => {
          state.kit = action
          state.status = 'READY'
        })
  }
})

export default kitProductSlice.reducer
