// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {url, authUrl} from "../../../../services/url"
axios.defaults.withCredentials = true

export const getAllData = createAsyncThunk('appUsers/getAllData', async params => {
  const response = await axios.get(`${url}/users`, { params })

  return {
    data: response.data.data,
    total: response.data.meta.total,
    perPage: response.data.meta.per_page,
    params
  }
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`${url}/users`, {params})
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${url}/users/${id}`, { id })
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user) => {
  const data = await axios.post(`${url}/users`, user)
  return data
})

export const getCepInfo = createAsyncThunk('appUsers/getCepInfo', async cep => {
  const response = await axios.get(`${url}/getCepInfo/${cep}`)

  return response
})

export const addUserPublic = createAsyncThunk('appUsers/addUserPublic', async (user) => {
  try {
    await axios.get(`${authUrl}/sanctum/csrf-cookie`)

    axios.defaults.withCredentials = true

    const data = await axios.post(`${authUrl}/user`, user)

    return data
  } catch (error) {
    return error
  }
})

export const update = createAsyncThunk('update', async (item, {dispatch}) => {
  await axios.put(`${url}/users/${item.id}`, item)
  await dispatch(getAllData())

  return item
})

export const manyUpdateUsers = createAsyncThunk('many-update-users', async (item) => {
  await axios.put(`${url}/users-mass-update`, item)

  return item
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const getFilter = createAsyncThunk('getFilter/user', async (item) => {
  const response = await axios.get((item.name !== undefined && item.email !== undefined ? `${url}/users?name=${item?.name}&email=${item?.email}&role=${item?.role}` : `${url}/users?role=${item?.role}`))
  return response
})

export const storeUser = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: '',
    params: {},
    allData: [],
    selected: null,
    allUsers: [],
    status: 'READY',
    cepInfo: '',
    cepInfoStatus: '',
    userAdded: {
      statusText: "",
      payload: ""
    },
    userAddedPublic: {
      statusText: "",
      payload: "",
      error: ""
    },
    filter: {
      statusText: "",
      payload: ""
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getAllData.fulfilled, (state, action) => {
      state.data = action.payload
      state.status = "FULLFILED"
    })
    .addCase(getAllData.pending, (state) => {
      state.status = "PENDING"
    })
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getUser.fulfilled, (state, action) => {
      state.selected = action.payload
    })
    .addCase(addUser.fulfilled, (state, action) => {
      state.userAdded.payload = action.payload
      state.userAdded.statusText = `Success`
    })
    .addCase(addUser.pending, (state, action) => {
      state.userAdded.payload = action.payload
      state.userAdded.statusText = "pending"
    })
    .addCase(addUser.rejected, (state, action) => {
      state.userAdded.payload = action.payload
      state.userAdded.statusText = 'Failed'
    })
    .addCase(addUserPublic.fulfilled, (state, action) => {
      if (action.payload?.status >= 200 && action.payload?.status < 300) {
        state.userAddedPublic.payload = action.payload
        state.userAddedPublic.statusText = `Success`
      } else {
        state.userAddedPublic.payload = action.payload.response?.data?.errors
        state.userAddedPublic.statusText = `Error`
      }
    })
    .addCase(update.fulfilled, (state, action) => {
      state.selected = action.payload
      state.status = 'Atualizado com sucesso'
    })
    .addCase(update.rejected, (state, action) => {
      state.selected = action.error
      state.status = 'Erro ao atualizar'
    })
    .addCase(manyUpdateUsers.fulfilled, (state, action) => {
      state.selected = action.payload
      state.status = 'Atualização em massa realizada com sucesso'
    })
    .addCase(manyUpdateUsers.rejected, (state, action) => {
      state.selected = action.error
      state.status = 'Erro ao realizar atualização em massa'
    })
    .addCase(getFilter.fulfilled, (state, action) => {
      state.filter.payload = action.payload?.data.data
      state.filter.statusText = `Success`
    })
    .addCase(getCepInfo.fulfilled, (state, action) => {
      state.cepInfo = action.payload.data
      state.cepInfoStatus = `Success`
    })
    // eslint-disable-next-line no-unused-vars
    .addCase(getCepInfo.pending, (state, _action) => {
      state.cepInfoStatus = `PENDING`
    })
    // eslint-disable-next-line no-unused-vars
    .addCase(getCepInfo.rejected, (state, _action) => {
      state.cepInfoStatus = `ERROR`
    })
  }
})

export default storeUser.reducer
