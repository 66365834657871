import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../services/url'
import axios from 'axios'

const endpoint = `${url}/health_insurance`

export const getAll = createAsyncThunk('all/health_insurance', async params => {
    const { data: { data, meta: { total, per_page } } } = await axios.get(endpoint, { params })
    return {
        data,
        total,
        perPage: per_page,
        params
    }
})

export const create = createAsyncThunk('create/health_insurance', async (body) => {
    const data = await axios.post(endpoint, body)

    return data
})

export const remove = createAsyncThunk('remove/health_insurance', async (id) => {
    const data = await axios.delete(`${endpoint}/${id}`)

    return data
})

export const update = createAsyncThunk('update/health_insurance', async (item, { dispatch }) => {
    await axios.put(`${endpoint}/${item[0].id}`, item[0])
    await dispatch(getAll())
    return item[0]
})

export const clearStatus = createAsyncThunk('status/health_insurance', async (status) => {
    return status
})

export const getAllHealthInsurance = createAsyncThunk('listHealthInsurance', async () => {
    const response = await axios.get(`${url}/health_insurance`)
    return response
})

export const storeHealthInsurance = createSlice({
    name: 'healthInsurance',
    initialState: {
        data: [],
        status: 'READY',
        total: 1,
        params: {},
        selected: null,
        error: '',
        notification: {
            status: '',
            text: ''
        },
        allHealthInsurance: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload
                state.status = 'FULFIELLED'
            })
            .addCase(getAll.pending, (state) => {
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.data = action.payload
                state.error = action.error
                state.status = 'ERROR'
            })
            .addCase(create.fulfilled, (state, action) => {
                state.notification.text = action.payload.data.message
                state.notification.status = action.payload.status
                state.status = 'FULFIELLED'
            })
            .addCase(create.pending, (state) => {
                state.status = 'PENDING'
            })
            .addCase(create.rejected, (state, action) => {
                state.data = action.payload
                state.error = action.error
                state.status = 'ERROR'
            })
            .addCase(remove.fulfilled, (state, action) => {
                state.notification.status = action.payload.status
                state.notification.text = action.payload.data.message
                state.status = 'REMOVED'
            })
            .addCase(remove.pending, (state) => {
                state.status = 'PENDING'
            })
            .addCase(remove.rejected, (state) => {
                state.status = 'ERROR'
            })
            .addCase(update.fulfilled, (state, action) => {
                state.notification.text = action.payload.data?.message
                state.notification.status = action.payload.status
                state.status = 'FULFIELLED'
            })
            .addCase(update.rejected, (state, action) => {
                state.selected = action.error
                state.status = 'ERROR'
            })
            .addCase(clearStatus.fulfilled, (state) => {
                state.notification.text = ''
                state.notification.status = ''
                state.status = 'READY'
            })
            .addCase(getAllHealthInsurance.fulfilled, (state, action) => {
                state.allHealthInsurance = action.payload.data
                state.status = 'READY'
            })
            .addCase(getAllHealthInsurance.pending, (state) => {
                state.loading = true
                state.status = 'PENDING'
            })
    }
})

export default storeHealthInsurance.reducer