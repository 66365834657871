import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/users/caregivers`

export const getCaregivers = createAsyncThunk('getcaregivers', async () => {
  const response = await axios.get(endpoint)
  return response
})

export const caregivers = createSlice({
  name: 'caregivers',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    sendFilter: {
      statusText: '',
      payload: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCaregivers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'READY'
      })
      .addCase(getCaregivers.pending, (state) => {
        state.data = true
        state.status = 'PENDING'
      })
  }
})

export default caregivers.reducer