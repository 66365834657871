import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { url } from '../../../services/url'
import axios from 'axios'

const endpoint = `${url}/stock_movements/collection`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('stock_movements/all', async params => {
  const response = await axios.get(`${url}/stock_movements`, { params })
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const get = createAsyncThunk('stock_movements/get', async id => {
  const response = await axios.get(`${url}/stock_movements/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk('stock_movements/collection/add', async (item, { dispatch }) => {
  await axios.post(endpoint, item)
  await dispatch(getAll())
  return item
})

export const addKit = createAsyncThunk('stock_movements/collection/addKit', async (item, { dispatch }) => {
  await axios.post(`${url}/stock_movements/kits/collection`, item)
  await dispatch(getAll())
  return item
})

export const update = createAsyncThunk('stock_movements/collection/update', async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(getAll())
  return item
})

export const remove = createAsyncThunk('stock_movements/collection/remove', async (item, { dispatch }) => {
  await axios.delete(`${endpoint}/${item}`)
  await dispatch(getAll())
  return (item)
})

export const getAllMovements = createAsyncThunk('stock_movements/collection/all_list', async () => {
  const response = await axios.get(`${url}/stock_movements`)
  return response
}) 

export const addXml = createAsyncThunk('stock_movements/collection/addXml', async (item, { dispatch }) => {
  const response = await axios.post(`${url}/stock_movements/xml`, item, {
    headers: {
      'Content-Type': 'application/xml'
    }
  })
  await dispatch(getAll())
  return response
})

export const stockSlice = createSlice({
  name: 'stock_movements/collection',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    all_movements: [],
    addMovement: {
      status: ""
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'READY'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'READY'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.selected = action.payload
        state.addMovement.status = `SUCCESS ${Math.random()}`
      })
      .addCase(add.pending, (state) => {
        state.status = "PENDING"
      })
      .addCase(add.rejected, (state, action) => {
        state.addMovement.status = `ERROR ${Math.random()}`
        state.error = action.payload || 'nao deu'
        state.loading = false
      })
      .addCase(addKit.fulfilled, (state, action) => {
        state.selected = action.payload
        state.addMovement.status = `SUCCESS ${Math.random()}`
      })
      .addCase(addKit.pending, (state) => {
        state.status = "PENDING"
      })
      .addCase(addKit.rejected, (state, action) => {
        state.addMovement.status = `ERROR ${Math.random()}`
        state.error = action.payload || 'nao deu'
        state.loading = false
      })
      .addCase(addXml.fulfilled, (state, action) => {
        state.selected = action.payload
        state.addMovement.status = `SUCCESS ${Math.random()}`
      })
      .addCase(addXml.pending, (state) => {
        state.status = "PENDING"
      })
      .addCase(addXml.rejected, (state, action) => {
        state.addMovement.status = `ERROR ${Math.random()}`
        state.error = action.payload || 'nao deu'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SUCCESS'
      })
      .addCase(update.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'
      })
      .addCase(remove.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'

      })
      .addCase(getAllMovements.fulfilled, (state, action) => {
        state.all_movements = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllMovements.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
    }
})

export default stockSlice.reducer
