import mock from '../mock'

const data = {
  pricing: {
    basicPlan: {
      title: 'Start',
      img: require('@src/assets/images/lp/icons/01-plan.png').default,
      subtitle: 'Para novas agências RE/MAX',
      monthlyPrice: "299,90",
      yearlyPlan: {
        perMonth: "249,90",
        totalAnnual: "2.999,00",
        installments: '12x 249,90'
                  },
      planBenefits: [
        '12 usuários',
        'Artes Ilimitadas',
        'Estudo de Mercado',
        'Dossiê do corretor',
        'Plano de marketing',
        'Gerador de placas',
        'Gerador de contrato de  Gestão do imóvel'
      ],
      popular: false
    },
    standardPlan: {
      title: 'Decolar',
      img: require('@src/assets/images/lp/icons/02-plan.png').default,
      subtitle: 'Para agência em tração',
      monthlyPrice: "399,90",
      yearlyPlan: {
        perMonth: "333,25",
        totalAnnual: "3.999,00",
        installments: '12x 333,25'
      },
      planBenefits: [
        '20 usuários',
        'Artes Ilimitadas',
        'Estudo de Mercado',
        'Dossiê do corretor',
        'Plano de marketing',
        'Gerador de placas',
        'Gerador de contrato de  Gestão do imóvel'
      ],
      popular: false
    },
    enterprisePlan: {
      title: 'Ilimitado',
      img: require('@src/assets/images/lp/icons/03-plan.png').default,
      subtitle: 'Para agências de alta performance',
      monthlyPrice: "499,90",
      yearlyPlan: {
        perMonth: "416,58",
        totalAnnual: "4999,00",
        installments: '12x 416,58'
      },
      planBenefits: [
          'Usuários Ilimitados',
        'Artes Ilimitadas',
        'Estudo de Mercado',
        'Dossiê do corretor',
        'Plano de marketing',
        'Gerador de placas',
        'Gerador de contrato de  Gestão do imóvel'
      ],
      popular: false
    },
    qandA: [
      {
        question: 'Quais usuários posso adicionar ao meu plano?',
        ans:
          'Você pode adicionar brokers, staff, corretores e estagiários ao seu plano conforme a quantidade de usuários contratada para o seu plano'
      },
      {
        question: 'Quais ferramentas posso utilizar no meu plano?',
        ans:
          'A plataforma da REMARKT disponibiliza ferramentas de dossiê do agente, plano de marketing, estudo de mercado, gerador de placas, gerador de contrato, apresentação da franquia, e claro, o gerador de artes em todos os planos'
      },
      {
        question: 'Quais os tipos de artes disponíveis na plataforma',
        ans:
          'Artes para impressão e para redes sociais para o marketing pessoal do agente, marketing do imóvel, marketing de posicionamento. Para brokers a plataforma também disponibilza artes exclusivas de recrutamento, reconhecimento, marketing institucional, marketing imobiliário, materiais para sinalização e padronização da loja'
      },
      {
        question: 'O que acontece quando preciso mudar um usuário do meu plano',
        ans:
          'A qualquer momento você poderá alterar os usuários que utilizam o sue plano, removendo-os do seu plano e' +
            'adicionando outro no lugar. Mas fique atento: não é possível reativar um usuário anteriormente removido num período anterior a 30 dias. '
      },
      {
        question: 'Quais as formas de pagamento aceitas?',
        ans:
        'Parcelamentos de plano anual podem ser pagos apenas com cartão de crédito.' +
            ' Planos mensais podem ser pagos por boleto ou por um cartão de crédito cadastrado. No caso de pagamento com cartão de crédito, a cobrança é feita automaticamente no cartão de crédito na data do vencimento do seu plano. '
      },
      {
        question: 'E se eu quiser cancelar?',
        ans:
            'A REMARKT é um serviço por assinatura, você paga e usa a plataforma enquanto seu plano estiver vigente e adimplente. Planos anuais podem ser' +
            'cancelados sem qualquer onus em até 30 dias após a contratação do plano. Caso o cancelamento ocorra após 30 dias da data do primeiro pagamento, ' +
            'uma multa de quebra de fidelidade é aplicada equivalente a 50% do valor restante do contrato.'
      }
    ]
  }
}

mock.onGet('/pricing/data').reply(() => [200, data.pricing])
