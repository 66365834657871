import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/procedure_patient_HI`

export const getprocedure = createAsyncThunk('getProcedure/procedure_patient_health_insurance', async () => {
  const response = await axios.get(endpoint)
  return response
})

export const procedure = createSlice({
  name: 'procedure',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    sendFilter: {
      statusText: '',
      payload: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getprocedure.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'READY'
      })
      .addCase(getprocedure.pending, (state) => {
        state.data = true
        state.status = 'PENDING'
      })
  }
})

export default procedure.reducer
