// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import appUsers from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import forgotPassword from '../views/pages/authentication/forgotPassword/store'
import resetPassword from '../views/pages/authentication/resetPassword/store'

import agencies from '../views/panel/agency/store'
import states from '../views/panel/state/store'
import cities from '../views/panel/city/store'
import warehouse from '../views/stock/warehouse/store'
import suppliers from '../views/stock/suppliers/store'
import categories from '../views/stock/categories/store'
import units from '../views/stock/units/store'
import products from '../views/stock/products/store'
import stock_movements from '../views/stock_moves/store'
import movement_output from '../views/stock_moves/store/storeMovementOutput'
import patient from '../views/patient/store'
import pad from '../views/pad/store'
import healthInsurance from '../views/healthInsurance/store'
import users from '../views/panel/user/store'
import prescription from '../views/components/prescriptionTable/store'
import procedure from '../views/components/procedureTable/store'
import active_patient from '../views/dashboard/cards/store/ActivePatients'
import caregivers from '../views/dashboard/cards/store/UsersCaregivers'
import allergy from '../views/allergy/store'
import kit from '../views/stock/kits/store/kitProduct'
import service from '../views/stock/services/store'
import devices from '../views/devices/store'
import stocks from '@src/views/stock/stocks/store'
import productKit from '@src/views/stock/kits/store/productKit'
import monthly_output from '@src/views/components/monthlyOutput/store'
import scale from '@src/views/components/scaleCaregiver/store'
import specialties from '@src/views/panel/specialties/store'

const rootReducer = {
  auth,
  patient,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  agencies,
  states,
  cities,
  warehouse,
  suppliers,
  categories,
  products,
  units,
  stock_movements,
  pad,
  appUsers,
  healthInsurance,
  movement_output,
  forgotPassword,
  prescription,
  procedure,
  active_patient,
  caregivers,
  resetPassword,
  allergy,
  kit,
  service,
  devices,
  stocks, 
  productKit,
  monthly_output,
  scale,
  specialties
}

export default rootReducer
