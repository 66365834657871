import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {url} from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/measurement_unit`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('units/all', async params => {
  const response = await axios.get(endpoint, {params})
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const getAllUnit = createAsyncThunk('all', async () => {
  const response = await axios.get(endpoint)
  return response
})

export const get = createAsyncThunk('get', async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk('add', async (item, { dispatch }) => {
  await axios.post(endpoint, item)
  await dispatch(getAll())
  await dispatch(getAllUnit())
  return item
})

export const update = createAsyncThunk('update', async (item, {dispatch}) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(getAll())
  return item
})

export const remove = createAsyncThunk('remove', async (item, {dispatch}) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll())
  await dispatch(getAllUnit())
  return item
})


export const unitsSlice = createSlice({
  name: 'units',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    allUnit: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
            state.status = 'READY'
        })
        .addCase(getAll.pending, (state) => {
            state.loading = true
            state.status = 'PENDING'
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'READY'
          state.selected = action.payload
        })
        .addCase(add.fulfilled, (state, action) => {
            state.selected = action.payload
            state.status = 'adicionado com sucesso'
        })
        .addCase(add.rejected, (state, action) => {
            state.status = 'nao adicionada'
            state.error = action.payload || 'nao deu'
            state.loading = false
        })
        .addCase(update.fulfilled, (state, action) => {
          state.selected = action.payload
            state.status = `atualizado ${Math.random()}`
        })
        .addCase(update.rejected, (state, action) => {
          state.selected = action.error
            state.status = `nao_atualizado ${Math.random()}`
        })
        .addCase(remove.fulfilled, (state, action) => {
          state.selected = action.error
          state.status = 'removido com sucesso'
        })
        .addCase(remove.rejected, (state, action) => {
          state.selected = action.error
          state.status = 'não removido'
        })
        .addCase(getAllUnit.fulfilled, (state, action) => {
          state.status = 'READY'
          state.allUnit = action.payload.data
          state.total = action.payload.data?.meta?.total
        })
  }
})

export default unitsSlice.reducer
