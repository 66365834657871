import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../services/url'
import axios from 'axios'

const endpoint = `${url}/allergy`

export const getAll = createAsyncThunk('all/allergy', async params => {
  const { data: { data, meta: { total, per_page } } } = await axios.get(endpoint, { params })
  return {
    data,
    total,
    perPage: per_page,
    params
  }
})

export const create = createAsyncThunk('create/allergy', async (body, { dispatch }) => {
  const data = await axios.post(endpoint, body)
  await dispatch(getAll())
  return data
})

export const update = createAsyncThunk('update/allergy', async (body) => {
  console.log(body[0])
  await axios.put(`${endpoint}/${body[0].id}`, body[0])
  return body
})

export const clearStatus = createAsyncThunk('status/allergy', async (status) => {
  return status
})

export const remove = createAsyncThunk('remove/allergy', async (id) => {
  const data = await axios.delete(`${endpoint}/${id}`)

  return data
})

export const getAllAllergy = createAsyncThunk('listSuppliers', async () => {
  const response = await axios.get(endpoint)
  return response
})

export const storeAllergy = createSlice({
  name: 'allergy',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    notification: {
      status: '',
      text: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = 'FULFIELLED'
      })
      .addCase(getAll.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(create.fulfilled, (state, action) => {
        state.notification.text = action.payload.data.message
        state.notification.status = action.payload.status
        state.status = 'FULFIELLED'
      })
      .addCase(create.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(create.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.notification.status = action.payload.status
        state.notification.text = action.payload?.data?.message
        state.status = "Atualizado"
      })
      .addCase(update.rejected, (state, action) => {
        state.notification.text = action.error
        state.notification.status = "ERROR"
      })
      .addCase(clearStatus.fulfilled, (state) => {
        state.notification.text = ''
        state.notification.status = ''
        state.status = 'READY'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.notification.status = action.payload.status
        state.notification.text = action.payload.data.message
        state.status = 'REMOVED'
      })
      .addCase(remove.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(remove.rejected, (state) => {
        state.status = 'ERROR'
      })
      .addCase(getAllAllergy.fulfilled, (state, action) => {
        state.data = action.payload.data.data
        state.status = 'SUCCESS'
      })
      .addCase(getAllAllergy.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
  }
})

export default storeAllergy.reducer
