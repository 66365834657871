import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {url} from '../../../services/url'
import axios from 'axios'

const endpoint = `${url}/patient`

export const getAll = createAsyncThunk('patient', async params => {
  const { data: { data, meta: { total, per_page } } } = await axios.get(endpoint, { params })

  return {
    data,
    total,
    perPage: per_page,
    params
  }
})

export const getAllDashboard = createAsyncThunk('patientDashboard', async params => {
  const { data: { data, meta: { total, per_page } } } = await axios.get(endpoint, { params })

  return {
    data,
    total,
    perPage: per_page,
    params
  }
})

export const getPages = createAsyncThunk('pages', async(params) => {
  const response = await axios.get(`${endpoint}?pages=${params.perPage}`)
  return response
})

export const create = createAsyncThunk('create/patient', async (body) => {
  const { data: { message }, statusText } = await axios.post(endpoint, body)

  return { message, statusText }
})

export const getPatient = createAsyncThunk('get/patient', async (id) => {
  const result = await axios.get(`${endpoint}/${id}`, { id })

  return result
})

export const getPatientAsync = createAsyncThunk('get/getPatientAsync', async (id) => {
  const result = await axios.get(`${endpoint}/${id}`, { id })

  return result
})

export const removePatient = createAsyncThunk('products/remove', async (item, { dispatch }) => {
  const response = await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll())
  return response
})

export const updatePatient = createAsyncThunk('update/patient', async (body) => {
  const result = await axios.put(`${endpoint}/${body.id}`, body)

  return result
})


export const manyUpdatePatient = createAsyncThunk('many-update-patient', async (body) => {
  const result = await axios.put(`${endpoint}s-mass-update`, body)

  return result
})

export const newPatientStatus = createAsyncThunk('status/patient', async (status) => {

  return status
})

export const addFinancialResp = createAsyncThunk('financial_resp', async (data, {dispatch}) => {
  const result = await axios.post(`${url}/financial_resp`, data)
  await dispatch(getPatientAsync(data.patient_id))
  return result
})

export const updateFinancialResp = createAsyncThunk('financial_resp/update', async (body, {dispatch}) => {
  const result =  await axios.put(`${url}/financial_resp/${body.id}`, body)
  await dispatch(getPatientAsync(body.patient_id))
  return result
})

export const addVitalSigns = createAsyncThunk('vital_signs/create', async (body, {dispatch}) => {
  const result = await axios.post(`${url}/vital_sign`, body)
  await dispatch(getPatientAsync(body.patient_id))
  return result
})

export const addPatientNursingPrescription = createAsyncThunk('patient_nursing_prescription/create', async (body, {dispatch}) => {
  await axios.post(`${url}/patient_nursing_prescriptions`, body)
  await dispatch(getPatientAsync(body.patient_id))
})

export const addPatientNursingAnnotation = createAsyncThunk('patient_nursing_annotation/create', async (body, {dispatch}) => {
  await axios.post(`${url}/patient_nursing_annotations`, body)
  await dispatch(getPatientAsync(body.patient_id))
})

export const addPatientEvacuation = createAsyncThunk('patient_evacuations/create', async (body, {dispatch}) => {
  await axios.post(`${url}/patient_evacuations`, body)
  await dispatch(getPatientAsync(body.patient_id))
})

export const getAllSign = createAsyncThunk('vital_signs/get', async (id) => {
  const result = await axios.get(`${url}/vital_sign/${id}`)

  return result
})

export const createEvolution = createAsyncThunk('create/evolution_patient', async (body, {dispatch}) => {
  await axios.post(`${url}/evolution`, body)
  await dispatch(getPatientAsync(body.patient_id))
})


export const savePrescritionDone = createAsyncThunk('create/patient_prescription_done', async (body, {dispatch}) => {
  await axios.post(`${url}/patient_prescription_done`, body)
  await dispatch(getPatientAsync(body.patient_id))
})

export const getAllPatients = createAsyncThunk('listPatients', async (params) => {
  const response = await axios.get(endpoint, { params })
  return response.data
})

export const storePatient = createSlice({
  name: 'patient',
  initialState: {
    data: [],
    dataDashBoard: [],
    item: {},
    events: [],
    status: 'READY',
    statusDashBoard: 'READY',
    total: 1,
    totalDashBoard: 1,
    params: {},
    paramsDashBoard: {},
    selected: null,
    error: '',
    newPatient: {
      statusText: '',
      payload: ''
    },
    updatedPatient: {
      statusText: '',
      payload: ''
    },
    financialResp: {
      statusText: '',
      payload: ''
    },
    vitalSigns: {
      statusText: '',
      payload: ''
    },
    evolutionPatient: {
      statusText: '',
      payload: ''
    },
    allPatients: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.status = 'READY'
      })
      .addCase(getAll.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(getAllDashboard.fulfilled, (state, action) => {
        state.dataDashBoard = action.payload.data
        state.paramsDashBoard = action.payload.params
        state.totalDashBoard = action.payload.total
        state.statusDashBoard = 'READY'
      })
      .addCase(getAllDashboard.pending, (state) => {
        state.statusDashBoard = 'PENDING'
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.data = action.payload.data.data
        state.perPage = action.payload.data.meta.per_page
        state.status = 'READY'
      })
      .addCase(getPages.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(create.fulfilled, (state, action) => {
        state.newPatient.payload = action.payload
        state.newPatient.statusText = 'OK'
        state.status = 'CREATED'
      })
      .addCase(create.pending, (state) => {
        state.status = 'PENDING'
        state.events = []
      })
      .addCase(create.rejected, (state, action) => {
        state.newPatient.payload = action.error
        state.newPatient.statusText = 'ERROR'
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.item = action.payload.data
        state.params = action.payload.params
        state.status = 'READY'

        let prescriptionsDone = []
        prescriptionsDone = action.payload?.data?.data?.prescriptionDone

        if (prescriptionsDone?.length > 0) {
          prescriptionsDone.map(p => {
            state.events.push({
              title: `${p.tarefa  }`,
              p_id: p.id,
              start: p.done_at,
              extendedProps: {
                description: p.description,
                createdBy: p.userName,
                calendar: 'DonePrescription'
              }
            })
          })
        }

      })
      .addCase(getPatient.pending, (state) => {
        state.status = 'LOADING'
        state.events = []
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.data = action.payload
        state.status = 'ERROR'
      })
      .addCase(getPatientAsync.fulfilled, (state, action) => {
        state.item = action.payload.data
        state.params = action.payload.params
        state.status = 'READY'

        let prescriptionsDone = []
        prescriptionsDone = action.payload?.data?.data?.prescriptionDone

        if (prescriptionsDone?.length > 0) {
          prescriptionsDone.map(p => {
            state.events.push({
              title: `${p.tarefa  }`,
              p_id: p.id,
              start: p.done_at,
              extendedProps: {
                description: p.description,
                createdBy: p.userName,
                calendar: 'DonePrescription'
              }
            })
          })
        }

      })
      .addCase(getPatientAsync.pending, (state) => {
        state.status = 'READY'
        state.events = []
      })
      .addCase(getPatientAsync.rejected, (state, action) => {
        state.data = action.payload
        state.status = 'ERROR'
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.item = action.payload.data
        state.params = action.payload.params
        state.status = 'UPDATED'

        let prescriptionsDone = []
        prescriptionsDone = action.payload?.data?.data?.prescriptionDone

        if (prescriptionsDone?.length > 0) {
          prescriptionsDone.map(p => {
            state.events.push({
              title: `${p.tarefa  }`,
              p_id: p.id,
              start: p.done_at,
              extendedProps: {
                description: p.description,
                createdBy: p.userName,
                calendar: 'DonePrescription'
              }
            })
          })
        }
      })
      .addCase(updatePatient.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(newPatientStatus.fulfilled, (state, action) => {
        state.newPatient.payload = action.payload
        state.updatedPatient.statusText = ''
        state.financialResp.statusText = ''
        state.newPatient.statusText = ''
        state.vitalSigns.statusText = ''
        state.evolutionPatient.statusText = ''
      })
      .addCase(addFinancialResp.fulfilled, (state, action) => {
        state.financialResp.payload = action.payload
        state.financialResp.statusText = 'OK'
      })
      .addCase(addFinancialResp.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(addFinancialResp.rejected, (state, action) => {
        state.financialResp.payload = action.error
        state.financialResp.statusText = 'ERROR'
      })
      .addCase(updateFinancialResp.fulfilled, (state, action) => {
        state.financialResp.payload = action.payload
        state.financialResp.statusText = 'UPDATED'
      })
      .addCase(updateFinancialResp.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(updateFinancialResp.rejected, (state, action) => {
        state.financialResp.payload = action.error
        state.financialResp.statusText = 'ERROR'
      })
      .addCase(addVitalSigns.fulfilled, (state, action) => {
        state.vitalSigns.payload = action.payload
        state.vitalSigns.statusText = 'OK'
      })
      .addCase(addVitalSigns.pending, (state) => {
        state.vitalSigns.statusText = 'PENDING'
      })
      .addCase(addVitalSigns.rejected, (state, action) => {
        state.vitalSigns.payload = action.payload
        state.vitalSigns.statusText = 'ERROR'
      })
      .addCase(createEvolution.fulfilled, (state, action) => {
        state.evolutionPatient.payload = action.payload
        state.evolutionPatient.statusText = 'OK'
      })
      .addCase(createEvolution.pending, (state) => {
        state.status = 'PENDING'
      })
      .addCase(createEvolution.rejected, (state, action) => {
        state.evolutionPatient.payload = action.error
        state.evolutionPatient.statusText = 'ERROR'
      })
      .addCase(getAllPatients.fulfilled, (state, action) => {
        state.allPatients = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllPatients.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(manyUpdatePatient.fulfilled, (state, action) => {
        state.updatedPatient.payload = action.payload
        state.updatedPatient.statusText = 'OK'
      })
      .addCase(manyUpdatePatient.pending, (state) => {
        state.updatedPatient.statusText = 'PENDING'
      })
      .addCase(manyUpdatePatient.rejected, (state, action) => {
        state.updatedPatient.payload = action.payload
        state.updatedPatient.statusText = 'ERROR'
      })
  }
})

export default storePatient.reducer
