import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { url } from '../../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/forgot-password`
axios.defaults.withCredentials = true

export const add = createAsyncThunk('forgot-password/add-email', async (item, { dispatch }) => {
  console.log(item)
  await axios.post(endpoint, item)
  await dispatch(getAll())
  return item
})


export const forgotPasswordSlice = createSlice({
  name: 'forgot-password',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(add.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SUCCESS'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload || 'nao deu'
        state.loading = false
      })
    }
})

export default forgotPasswordSlice.reducer
