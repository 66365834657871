import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { url } from '../../../services/url'
import axios from 'axios'

const endpoint = `${url}/stock_movements/collection/exit`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('stock_movements/collection/exit/all', async params => {
  const response = await axios.get(`${url}/stock_movements/exit`, { params })
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const get = createAsyncThunk('stock_movements/collection/exit/get', async id => {
  const response = await axios.get(`${url}/stock_movements/exit/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk('stock_movements/collection/exit/add', async (item, { dispatch }) => {
  await axios.post(endpoint, item)
  await dispatch(getAll())
  return item
})

export const update = createAsyncThunk('stock_movements/collection/exit/update', async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(getAll())
  return item
})

export const remove = createAsyncThunk('stock_movements/collection/exit/remove', async (item, { dispatch }) => {
  await axios.delete(`${endpoint}/${item}`)
  await dispatch(getAll())
  return (item)
})

export const getAllMovementOutput = createAsyncThunk('stock_movements/collection/exit/all_list', async () => {
  const response = await axios.get(`${url}/stock_movements/exit`)
  return response
}) 


export const exitSlice = createSlice({
  name: 'exit',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    all_movements_output: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.status = 'READY'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'READY'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = `SUCCESS ${Math.random()}`
      })
      .addCase(add.rejected, (state, action) => {
        state.status = `ERROR ${Math.random()}`
        state.error = action.payload || 'nao deu'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.selected = action.payload
        state.status = 'SUCCESS'
      })
      .addCase(update.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'
      })
      .addCase(remove.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ERROR'

      })
      .addCase(getAllMovementOutput.fulfilled, (state, action) => {
        state.all_movements_output = action.payload.data
        state.status = 'READY'
      })
      .addCase(getAllMovementOutput.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
    }
})

export default exitSlice.reducer
