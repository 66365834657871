import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { url } from '../../../../services/url'
import axios from 'axios'
axios.defaults.withCredentials = true
const endpoint = `${url}/scale`

export const getAll = createAsyncThunk('all/scale', async params => {
  const { data: { data, meta: { total, per_page } } } = await axios.get(endpoint, { params })
  return {
    data,
    total,
    perPage: per_page,
    params
  }
}) 

export const create = createAsyncThunk('create/scale', async (body, { dispatch }) => {
  const data = await axios.post(endpoint, body)
  await dispatch(getAll({ patient_id: body?.id}))
  return data
})

export const getPatientScale = createAsyncThunk('scale/getPatient', async (patient) => {
  const response = await axios.get(`${endpoint}/patient/${patient.id}`, {
    params: {
      per_month: patient.per_month
    }
  })

  return response
})

export const update = createAsyncThunk('scale/update', async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item?.id}`, item)
  await dispatch(getPatientScale({ id: item.patient_id}))
  return item
})

export const manyUpdates = createAsyncThunk('scale/manyupdates', async (item) => {
  await axios.put(`${endpoint}-many-update`, item)

  return item
})

export const remove = createAsyncThunk('scale/remove', async (element) => {
  const el = element
  const response = await axios.delete(`${endpoint}/patient/${el?.patient_id}`, { data: { id: el.id } })
  return response
})

export const removePerPeriods = createAsyncThunk('scale/many-delete', async (element) => {
  const response = await axios.delete(`${endpoint}-many-delete`,  { data: { scale_ids: element } })

  return response
})

export const getUserScale = createAsyncThunk('scale/getUser', async id => {
  const response = await axios.get(`/scale/user/${id}`, { id })
  return response.data
})

export const getTableData = createAsyncThunk('getTableData', async (params) => {
  const response = await axios.get(`${endpoint}-table-data`, { params })

  return response.data
})

export const storeScale = createSlice({
  name: 'scale',
  initialState: {
    data: [],
    dataTable: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    notification: {
      status: '',
      text: '',
      patient_id: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload
        state.status = 'FULFIELLED'
      })
      .addCase(getAll.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(create.fulfilled, (state, action) => {
        state.notification.text = action.payload.data.message
        state.notification.patient_id = action.payload.data.patient_id
        state.notification.status = `sucesso!`
        state.status = 'FULFIELLED'
      })
      .addCase(create.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(create.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.notification.status = "Falha ao criar a escala!"
        state.status = 'ERROR'
      })
      .addCase(update.fulfilled, (state, action) => {
        state.notification.text = action.payload.data
        state.notification.status = "Atualizado com sucesso!"
        state.status = 'FULFIELLED'
      })
      .addCase(update.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(update.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.notification.status = "Falha ao atualziar!"
        state.status = 'ERROR'
      })
      .addCase(manyUpdates.fulfilled, (state, action) => {
        state.notification.text = action.payload.data
        state.notification.status = "Dias atualizado com sucesso!"
        state.status = 'FULFIELLED'
      })
      .addCase(manyUpdates.pending, (state) => {
        state.status = 'PEDING'
      })
      .addCase(manyUpdates.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.notification.status = "Falha ao atualziar os dias!"
        state.status = 'ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.notification.text = action.payload.data
        state.notification.status = "Removido com sucesso!"
        state.status = 'FULFIELLED'
      })
      .addCase(remove.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.notification.status = "Falha ao remover!"
        state.status = 'ERROR'
      })
      .addCase(removePerPeriods.fulfilled, (state, action) => {
        state.notification.text = action.payload.data
        state.notification.status = "Dias removido com sucesso!"
        state.status = 'FULFIELLED'
      })
      .addCase(removePerPeriods.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.notification.status = "Falha ao remover os dias!"
        state.status = 'ERROR'
      })
      .addCase(getUserScale.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.notification.status = action.payload.status
        state.status = 'FULFIELLED'
      })
      .addCase(getUserScale.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(getPatientScale.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.notification.status = action.payload.status
        state.status = 'FULFIELLED'
      })
      .addCase(getPatientScale.rejected, (state, action) => {
        state.data = action.payload
        state.error = action.error
        state.status = 'ERROR'
      })
      .addCase(getTableData.fulfilled, (state, action) => {
        state.dataTable = action.payload
        state.notification.status = action.payload.status
        state.status = 'READY_TABLE'
      })
      .addCase(getTableData.rejected, (state, action) => {
        state.dataTable = action.payload
        state.error = action.error
        state.status = 'ERROR_TABLE'
      })
  }
})

export default storeScale.reducer
