// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
 import {url} from '../../../../services/url'
// ** Axios Imports
import axios from 'axios'
axios.defaults.withCredentials = true

export const getData = createAsyncThunk('agencies/getData', async params => {
  axios.defaults.withCredentials = true
  const response = await axios.get(`${url}/agencies`, {params})
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})


export const getAgency = createAsyncThunk('agencies/getAgency', async id => {
  const response = await axios.get(`${url}/agency/${id}`, { id })
  return response.data
})

export const addAgency = createAsyncThunk('agencies/addAgency', async (agency, { dispatch, getState }) => {
  axios.defaults.withCredentials = true
  await axios.post(`${url}/agency`, agency)
  await dispatch(getData(getState().agencies.params))
  return agency
})

export const updateAgency = createAsyncThunk('agencies/updateAgency', async (agency) => {
  axios.defaults.withCredentials = true
  const response = await axios.put(`${url}/agency/${agency.id}`, agency)
  return response.data
})


export const agenciesSlice = createSlice({
  name: 'agencies',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selectedAgency: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getData.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
        })
        .addCase(getAgency.fulfilled, (state, action) => {
          state.selectedAgency = action.payload
        })
        .addCase(updateAgency.fulfilled, (state, action) => {
          state.selectedAgency = action.payload
        })
  }
})

export default agenciesSlice.reducer
