import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {url} from '../../../../services/url'
import axios from 'axios'

const endpoint = `${url}/product_category`
axios.defaults.withCredentials = true

export const getAll = createAsyncThunk('categories/all', async params => {
  const response = await axios.get(endpoint, {params})
  return { allData: response.data.data, data: response.data.data, totalPages: response.data.total, params }
})

export const getAllCategories = createAsyncThunk('listCategories', async () => {
  const response = await axios.get(endpoint)
  return response
}) 

export const get = createAsyncThunk('get', async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk('add', async (item, { dispatch }) => {
  await axios.post(endpoint, item)
  await dispatch(getAll())
  await dispatch(getAllCategories())
  return item
})

export const update = createAsyncThunk('update', async (item, {dispatch}) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  await dispatch(getAll())
  await dispatch(getAllCategories())
  return item
})

export const remove = createAsyncThunk('remove', async (item, {dispatch}) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll())
  await dispatch(getAllCategories())
  return item
})

export const categoryProduct = createAsyncThunk('category-product', async params => {
  const response = await axios.get(`${url}/product_category_product`, {params})
  return response
}) 

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    data: [],
    status: 'READY',
    total: 1,
    params: {},
    selected: null,
    error: '',
    allCategories: [],
    remove: {
      statusText: '',
      payload: ''
    },
    add: {
      statusText: '',
      payload: ''
    },
    update: {
      statusText: '',
      payload: ''
    },
    category_product: {
      payload: '',
      status: ''
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getAll.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
          state.status = 'READY'
        })
        .addCase(getAll.pending, (state) => {
            state.loading = true
            state.status = 'PENDING'
        })
        .addCase(get.fulfilled, (state, action) => {
          state.status = 'READY'
          state.selected = action.payload
        })
        .addCase(add.fulfilled, (state, action) => {
            state.selected = action.payload
            state.status = 'SUCCESS'
            state.add.statusText = `SUCCESS ${Math.random()}`
        })
        .addCase(add.rejected, (state, action) => {
            state.status = 'ERROR'
            state.error = action.payload || 'nao deu'
            state.loading = false
        })
        .addCase(update.fulfilled, (state, action) => {
          state.selected = action.payload
            state.status = 'SUCCESS'
            state.update.statusText = `SUCCESS ${Math.random()}`
        })
        .addCase(update.rejected, (state, action) => {
          state.selected = action.error
            state.update.statusText = `ERROR ${Math.random()}`
        })
        .addCase(remove.fulfilled, (state, action) => {
          state.remove.payload = action.payload
          state.remove.statusText = `SUCCESS ${Math.random()}`
        })
        .addCase(remove.rejected, (state, action) => {
          state.selected = action.error
            state.status = 'ERROR'
        })
        .addCase(getAllCategories.fulfilled, (state, action) => {
          state.allCategories = action.payload.data
          state.status = 'READY'
        })
        .addCase(getAllCategories.pending, (state) => {
          state.loading = true
          state.status = 'PENDING'
        })
        .addCase(categoryProduct.fulfilled, (state, action) => {
          state.category_product.payload = action.payload.data
          state.category_product.status = state
        })
        .addCase(categoryProduct.rejected, (state, action) => {
          state.category_product.payload = action.payload?.data
          state.category_product.status = "ERROR"
        })
  }
})

export default categoriesSlice.reducer
